import React from 'react';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { FormInput } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import { TextSMMedium } from 'styleguide/Texts';
import { ClientMeasurementKey, clientMeasurementKeyTitleMap } from '../types';

const Container = styled(FlexRow)`
  justify-content: space-between;
  border-bottom: 1px solid ${colors.neutral100};
  padding: ${Spacing.md}px 0;
`;

const MeasurementInput = styled(FormInput)`
  width: 96px;
  height: 40px;
  background-color: ${colors.white};
`;

interface MeasurementInputRowProps {
  value: string;
  unit: string;
  measurementKey: ClientMeasurementKey;
  onChange: (key: ClientMeasurementKey, value: string) => void;
}

export const MeasurementInputRow = ({
  value,
  unit,
  measurementKey,
  onChange,
}: MeasurementInputRowProps) => {
  return (
    <Container>
      <TextSMMedium>{`${clientMeasurementKeyTitleMap[measurementKey]} (${unit})`}</TextSMMedium>
      <MeasurementInput
        placeholder="-"
        value={value}
        onChange={e => onChange(measurementKey, e.currentTarget.value)}
        inputMode={'decimal'}
      />
    </Container>
  );
};
