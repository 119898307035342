import { LifeCycle } from './localStorageStores/lifecycle';
import { Account } from './localStorageStores/account';
import { Auth } from './localStorageStores/auth';
import { toJS } from 'mobx';
import { CoachUserPreferencesStore } from './localStorageStores/coachUserPreferences';
import { MyPrograms } from './localStorageStores/myPrograms';
import { ExerciseTemplates } from './localStorageStores/exerciseTemplates';
import { ClientInvites } from './localStorageStores/clientInvites';
import { Plans } from './localStorageStores/plan';
import { ExerciseTemplateCustomizations } from './localStorageStores/exerciseTemplateCustomizations';
import { WhatsNew } from './localStorageStores/whatsNew';
import { ProgramFolders } from './localStorageStores/programFolders';
import { BetaFeatures } from './localStorageStores/betaFeatures';
import { CoachConfigStore } from './localStorageStores/coachConfig';
import { Chats } from './localStorageStores/chats';
import { BundledProgramLibrary } from './localStorageStores/bundledProgramLibrary';
import { Team } from './localStorageStores/team';
import { TeamInvites } from './localStorageStores/teamInvites';
import { CoachsLeads } from './localStorageStores/coachsLeads';
import { ServerStorage } from './ServerStorage';

// These stores are persisted to and hydrated from local storage
export const localStorageStores = {
  lifecycle: new LifeCycle(),
  auth: new Auth(),
  account: new Account(),
  plan: new Plans(),
  userPreferences: new CoachUserPreferencesStore(),
  myPrograms: new MyPrograms(),
  programFolders: new ProgramFolders(),
  bundledProgramLibrary: new BundledProgramLibrary(),
  exerciseTemplates: new ExerciseTemplates(),
  exerciseTemplateCustomizations: new ExerciseTemplateCustomizations(),
  invites: new ClientInvites(),
  teamInvites: new TeamInvites(),
  whatsNew: new WhatsNew(),
  betaFeatures: new BetaFeatures(),
  coachConfig: new CoachConfigStore(),
  chats: new Chats(),
  team: new Team(),
  clientLeads: new CoachsLeads(),
  hydrateAll: () => {
    localStorageStores.account.hydrate();
    localStorageStores.plan.hydrate();
    ServerStorage.hydrateData();
    localStorageStores.userPreferences.hydrate();
    localStorageStores.myPrograms.hydrate();
    localStorageStores.programFolders.hydrate();
    localStorageStores.exerciseTemplates.hydrate();
    localStorageStores.invites.hydrate();
    localStorageStores.teamInvites.hydrate();
    localStorageStores.whatsNew.hydrate();
    localStorageStores.betaFeatures.hydrate();
    localStorageStores.coachConfig.hydrate();
    localStorageStores.chats.hydrate();
    localStorageStores.team.hydrate();
    localStorageStores.clientLeads.hydrate();
  },
};

if (process.env.NEXT_PUBLIC_ENV !== 'production') {
  (global as any).stores = localStorageStores;
  (global as any).toJS = toJS;
}
