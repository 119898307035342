import React, { useState } from 'react';
import { CIRCLE_DIAMETER, buildMonth, dayLabels, getDayType } from './utils';
import { View } from 'styleguide/View';
import styled from 'styled-components';
import { colors } from 'styleguide/colors';
import { TextSMMedium, TextSMRegular } from 'styleguide/Texts';
import { DividerLine } from 'styleguide/DividerLine';
import { Spacing } from 'styleguide/spacing';
import { SingleCircledIcon } from 'styleguide/CircledIcons';
import { FlexRow } from 'styleguide/Row';
import dayjs from 'dayjs';
import { DayView } from './components/DayView';
import { Weekday } from 'hevy-shared';
import { weekdayNumberMap } from 'utils/dateAndTimeUtils';

const Container = styled(View)`
  flex: 1;
`;

const WeekDayLabelRow = styled(View)`
  flex-direction: row;
  margin-top: ${Spacing.md}px;
`;

const WeekRow = styled(View)`
  flex-direction: row;
`;

interface CalendarProps {
  selectedDate?: string | null;
  durationDays?: number | null;
  markedDates?: string[];
  onSelectDate: (date: string | null) => void;
  style?: React.CSSProperties;
  firstWeekday: Weekday;
  isFutureDatesDisabled?: boolean;
}

export const Calendar = ({
  selectedDate,
  durationDays,
  markedDates,
  onSelectDate,
  style,
  firstWeekday,
  isFutureDatesDisabled,
}: CalendarProps) => {
  const [parsedSelectedDate, setMonth] = useState(dayjs(selectedDate ?? undefined));

  const goToPreviousMonth = () => {
    setMonth(parsedSelectedDate.subtract(1, 'month'));
  };
  const goToNextMonth = () => {
    setMonth(parsedSelectedDate.add(1, 'month'));
  };

  const firstWeekdayOffset = weekdayNumberMap[firstWeekday];

  const month = buildMonth({ month: parsedSelectedDate, firstWeekdayOffset });
  const monthTitle = parsedSelectedDate.format('MMM YYYY');

  return (
    <Container style={style}>
      <FlexRow style={{ justifyContent: 'space-between' }}>
        <SingleCircledIcon
          type="chevron-left"
          tint={colors.neutral900}
          diameter={CIRCLE_DIAMETER}
          containerStyle={{ cursor: 'pointer' }}
          onClick={goToPreviousMonth}
        ></SingleCircledIcon>
        <TextSMMedium>{monthTitle}</TextSMMedium>
        <SingleCircledIcon
          type="chevron-right"
          tint={colors.neutral900}
          diameter={CIRCLE_DIAMETER}
          containerStyle={{ cursor: 'pointer' }}
          onClick={goToNextMonth}
        ></SingleCircledIcon>
      </FlexRow>
      <WeekDayLabelRow>
        {dayLabels(firstWeekday).map((dayLabel, index) => (
          <TextSMRegular style={{ width: CIRCLE_DIAMETER, textAlign: 'center' }} key={index}>
            {dayLabel}
          </TextSMRegular>
        ))}
      </WeekDayLabelRow>

      <DividerLine style={{ marginBottom: Spacing.xs }} />

      <View style={{ gap: Spacing.xs, flex: 1 }}>
        {month.map((week, wIndex) => {
          return (
            <WeekRow key={`${wIndex}`}>
              {week.map((day, dIndex) => (
                <DayView
                  key={`${wIndex}:${dIndex}`}
                  day={day}
                  onSelectDate={onSelectDate}
                  dayType={getDayType(day.date, durationDays, selectedDate)}
                  firstWeekday={firstWeekday}
                  isFutureDatesDisabled={isFutureDatesDisabled}
                  isMarked={!!markedDates?.find(date => dayjs(date).isSame(day.date, 'day'))}
                ></DayView>
              ))}
            </WeekRow>
          );
        })}
      </View>
    </Container>
  );
};
