import { makeAutoObservable } from 'mobx';
import API from 'utils/API';
import { captureException } from '@sentry/nextjs';
import { CoachsShallowLibraryRoutine } from 'hevy-shared';

const ROUTINES_LOCAL_STORAGE_KEY = 'ROUTINES_LOCAL_STORAGE_KEY';

export class MyLibraryRoutines {
  routines: CoachsShallowLibraryRoutine[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  removeRoutine = (routineId: string) => {
    this.routines = this.routines.filter(routine => {
      return routine.id !== routineId;
    });
  };

  clearData = () => {
    this.routines = [];

    // We deprecated the use of local storage for routines, but we still clear it for now to ensure it doesn't stay around
    window.localStorage.removeItem(ROUTINES_LOCAL_STORAGE_KEY);
  };

  routineWithId = (routineId: string): CoachsShallowLibraryRoutine | undefined => {
    return this.routines.find(routine => {
      return routine.id === routineId;
    });
  };

  sortedRoutinesForProgram = (programId: string): CoachsShallowLibraryRoutine[] => {
    return this.routines
      .filter(routine => {
        return routine.program_id === programId;
      })
      .sort((r1, r2) => {
        return (r1.index ?? 0) - (r2.index ?? 0);
      });
  };

  isFetching = false;
  hasFetched = false;
  fetch = async () => {
    if (this.isFetching) {
      return;
    }
    try {
      this.isFetching = true;
      const response = await API.getSimplifiedLibraryRoutines();
      this.routines = response.data;
    } catch (e) {
      captureException(e);
      throw e;
    } finally {
      this.isFetching = false;
      this.hasFetched = true;
    }
  };
}
