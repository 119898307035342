import { makeAutoObservable } from 'mobx';
import { memoryStores } from 'state/memoryStores';

class ClientAggregatorClass {
  constructor() {
    makeAutoObservable(this);
  }

  get clientsWithoutProgram() {
    return memoryStores.clients.allClients.filter(client => !client.program);
  }

  get clientsAssignedAProgram() {
    return memoryStores.clients.allClients.filter(client => !!client.program);
  }
}

export const ClientAggregator = new ClientAggregatorClass();
