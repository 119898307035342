import { env } from 'env';
import EventEmitter from 'events';
import { HevyWebsocketMessage, isMessage } from 'hevy-shared';
import { debounce } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import Cookies from 'js-cookie';
import { log } from '../log';
import { fireAndForget } from '../async';
import { memoryStores } from 'state/memoryStores';
import toast from 'react-hot-toast';
import { ClientNowAvailableToast } from 'screens/Clients/components/ClientNowAvailableToast';
import { dashboard } from 'utils/globals/dashboard';

const CONNECTION_RETRY_INTERVAL_MS = 10000;
const EXPECTED_PING_INTERVAL_MS = 10000;

class HevyWebsocketClientClass {
  private _ws: WebSocket | undefined;
  private _shouldTryToConnect = false;
  private _isAttemptingToConnect = false;
  private _lastPingTimestamp?: number;

  hasConnectedOnce = false;
  hasDisconnectedUnexpectedely = false;

  isConnected = false;

  authToken?: string = Cookies.get('auth-token');

  private _debouncedTryToConnect: () => void;
  messageEventEmitter?: EventEmitter;
  dataChangeEventEmitter?: EventEmitter;

  constructor() {
    makeAutoObservable(this);
    this._debouncedTryToConnect = debounce(this._tryToConnect, 1000);

    if (typeof window !== 'undefined') {
      this.messageEventEmitter = new EventEmitter();
      this.dataChangeEventEmitter = new EventEmitter();
    }
  }

  monitorConnectionUntilDisconnected = () => {
    this.isConnected =
      !!this._ws && this._ws?.readyState === this._ws?.OPEN && this.isPingTimestampHealthy;
    if (this._shouldTryToConnect) {
      if (!this.isConnected) {
        this._debouncedTryToConnect();
      }
      setTimeout(this.monitorConnectionUntilDisconnected, 1000);
    }
  };

  disconnect = () => {
    this._shouldTryToConnect = false;
    this.isConnected = false;
    this.hasDisconnectedUnexpectedely = false;
    log(`✉️ WS disconnecting...`);
    if (!!this._ws) {
      this._lastPingTimestamp = undefined;
      this._ws.onerror = null;
      this._ws.onmessage = null;
      this._ws.onopen = null;
      this._ws.close();
    }
  };

  startTryingToConnect = () => {
    this._shouldTryToConnect = true;
    this._tryToConnect();
  };

  private _tryToConnect = () => {
    log(`✉️ WS connecting...`);
    if (this.isConnected) {
      log(`✉️ WS already connected`);
      return;
    }
    if (this._isAttemptingToConnect) {
      log(`✉️ WS already attempting to connect`);
      return;
    }
    this._isAttemptingToConnect = true;
    if (!this.authToken) {
      this._isAttemptingToConnect = true;
      setTimeout(this._debouncedTryToConnect, CONNECTION_RETRY_INTERVAL_MS);
      return;
    }
    const connectionURI = `${env.chatServerWsUrl}?userId=${encodeURIComponent(
      localStorageStores.account.id,
    )}&authToken=${encodeURIComponent(this.authToken)}&apiKey=${encodeURIComponent(
      env.chatApiKey,
    )}`;
    log(`Connecting to ${connectionURI}`);
    const ws = new WebSocket(connectionURI);

    ws.onopen = () => {
      this._lastPingTimestamp = Date.now();
      this.hasConnectedOnce = true;
      log('✉️ WS connected!');
      this._ws = ws;
      this.monitorConnectionUntilDisconnected();
    };

    ws.onmessage = event => {
      this.handleMessage(ws, event);
    };

    ws.onclose = event => {
      if (this._shouldTryToConnect) {
        this.hasDisconnectedUnexpectedely = true;
      }
      log(`✉️ WS event: ${JSON.stringify(event)}`);
      if (this._ws === ws) {
        log('✉️ WS disconnected');
        this._lastPingTimestamp = undefined;
        this._ws = undefined;
      }
      if (this._shouldTryToConnect) {
        setTimeout(this._debouncedTryToConnect, CONNECTION_RETRY_INTERVAL_MS);
      }
    };

    ws.onerror = event => {
      log(`✉️ WS error: ${JSON.stringify(event)}`);
      if (this._shouldTryToConnect) {
        setTimeout(this._debouncedTryToConnect, CONNECTION_RETRY_INTERVAL_MS);
      }
    };
    this._isAttemptingToConnect = false;
  };

  handleMessage = (ws: WebSocket, message: MessageEvent<any>) => {
    if (ws !== this._ws) {
      return;
    }
    try {
      const messageData: HevyWebsocketMessage = JSON.parse(message.data);
      if (messageData?.type === 'websocket-ping') {
        // Just a keep-alive
        this.sendMessageToServer({ type: 'websocket-pong' }, ws);
        this._lastPingTimestamp = Date.now();
        return;
      }
      switch (messageData.type) {
        case 'new-message':
          if (!isMessage(messageData.message)) {
            log('Invalid message received');
            return;
          }
          this.messageEventEmitter?.emit('new-message', messageData.message);
          break;
        case 'message-deleted':
          this.messageEventEmitter?.emit('message-deleted', messageData.message_id);
          break;
        case 'coaches-program-updated':
          fireAndForget([localStorageStores.myPrograms.fetch()]);
          this.dataChangeEventEmitter?.emit('program-updated', messageData.updated_program.id);
          break;
        case 'coaches-routine-updated':
          fireAndForget([memoryStores.myLibraryRoutines.fetch()]);
          this.dataChangeEventEmitter?.emit('routine-updated', messageData.updated_routine);
          break;
        case 'coaches-exercise-library-updated':
          fireAndForget([
            localStorageStores.exerciseTemplates.fetch(),
            localStorageStores.exerciseTemplateCustomizations.fetch(),
          ]);
          break;
        case 'coaches-clients-updated':
          const addedClient = messageData.new_client_ids[0];
          fireAndForget([
            memoryStores.clients.fetch(),
            memoryStores.clients.fetchClientWorkoutHistory(),
            localStorageStores.invites.fetch(),
            dashboard.refresh(),
          ]).then(() => {
            const client = memoryStores.clients.clientForUserId(addedClient);
            if (!client) {
              return;
            }
            toast.success(ClientNowAvailableToast(client), {
              id: 'client-invite-accepted',
              duration: 6000,
            });
          });
          break;

        default:
          console.warn(`${messageData.type} not supported`);
      }
    } catch (error) {
      log(`✉️ WS error: unable to parse message as JSON:`);
      log(message);
      return;
    }
  };

  sendMessageToServer = (message: HevyWebsocketMessage, ws: WebSocket) => {
    log(`Sending ${message.type} message`);
    ws.send(JSON.stringify(message));
  };

  sendMessage = (message: string) => {
    if (!this.isConnected) {
      throw new Error('Not connected');
    }

    this._ws?.send(message);
  };

  get isPingTimestampHealthy() {
    return (
      !!this._lastPingTimestamp &&
      this._lastPingTimestamp > Date.now() - EXPECTED_PING_INTERVAL_MS * 2
    );
  }
}

export const HevyWebsocketClient = new HevyWebsocketClientClass();
