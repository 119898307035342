import React from 'react';
import styled from 'styled-components';
import { Modal } from 'styleguide/Modal';
import { CropImage } from './CropImage';

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 80vh;
`;

interface CropImageModalProps {
  imageUrl?: string;
  aspect?: number;
  onImageCropped: (url: string) => void;
  onClose: () => void;
}

export const CropImageModal = ({
  imageUrl,
  aspect,
  onImageCropped,
  onClose,
}: CropImageModalProps) => {
  return (
    <Modal isOpen={!!imageUrl} onClose={onClose}>
      <CropContainer>
        <CropImage
          aspect={aspect}
          imageUrl={imageUrl}
          onImageCropped={onImageCropped}
          onCancel={onClose}
        />
      </CropContainer>
    </Modal>
  );
};
