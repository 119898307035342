'use client';
import { CoachsShallowLibraryRoutine } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { sendEvent } from 'utils/analyticsEvents';

interface SelectRoutineViewModelProps {
  onClose: () => void;
  onAddRoutinesClick: (routines: CoachsShallowLibraryRoutine[]) => void;
  onCreateRoutineClick: () => Promise<void>;
  maxRoutines?: number;
}

export class SelectRoutineViewModel {
  selectedRoutineIds: string[] = [];
  isloading: boolean = false;
  isCreatingRoutine: boolean = false;
  searchText: string = '';
  onAddRoutinesClick: (routines: CoachsShallowLibraryRoutine[]) => void;
  onCreateRoutineClick: () => Promise<void>;
  onClose: () => void;
  maxRoutines?: number;

  constructor({
    onClose,
    onAddRoutinesClick,
    onCreateRoutineClick,
    maxRoutines,
  }: SelectRoutineViewModelProps) {
    makeAutoObservable(this);
    this.onClose = onClose;
    this.maxRoutines = maxRoutines;
    this.onAddRoutinesClick = onAddRoutinesClick;
    this.onCreateRoutineClick = onCreateRoutineClick;
  }

  get routinesFromProgramTemplates() {
    const myProgramIds = localStorageStores.myPrograms.allPrograms.map(program => {
      return program.id;
    });

    // Routines that are a part of a program, but not assigned to a client
    return memoryStores.myLibraryRoutines.routines.filter(routine => {
      return (
        routine.program_id &&
        (routine.username === localStorageStores.account.username || !routine.username) &&
        // Make sure this routine's program is one of MY programs.
        // It's possible it's a routine that I own because another coach assigned a program to me
        myProgramIds.includes(routine.program_id)
      );
    });
  }

  get routines() {
    const routines = this.routinesFromProgramTemplates ?? [];
    return routines.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }

  get filteredRoutines() {
    return this.routines.filter(routine => {
      return routine.title.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  onSearchTextChanged = (text: string) => {
    this.searchText = text;
  };

  isRoutineSelected = (routineId: string): boolean => {
    return (
      this.selectedRoutineIds.find(otherId => {
        return otherId === routineId;
      }) !== undefined
    );
  };

  onSelectRoutine = (routineId: string) => {
    if (this.isRoutineSelected(routineId)) {
      this.selectedRoutineIds = this.selectedRoutineIds.filter(otherId => {
        return otherId !== routineId;
      });
    } else {
      this.selectedRoutineIds.push(routineId);
    }
  };

  onCreateRoutinePress = async () => {
    sendEvent('selectRoutineModal_createRoutine_press');
    this.isCreatingRoutine = true;
    await this.onCreateRoutineClick();
    this.isCreatingRoutine = false;
    this.onClose();
  };

  onAddRoutinesButtonPress = () => {
    sendEvent('selectRoutineModal_copyRoutineToProgram_press');
    const routines = memoryStores.myLibraryRoutines.routines.filter(routine => {
      return this.isRoutineSelected(routine.id);
    });
    this.onAddRoutinesClick(routines);
    this.onClose();
  };

  get isSelectRoutineButtonEnabled() {
    return (
      !this.isOverRoutineLimit &&
      this.selectedRoutineIds.length > 0 &&
      !this.isCreatingRoutine &&
      !this.isloading &&
      this.filteredRoutines.length > 0
    );
  }

  get isOverRoutineLimit() {
    return this.maxRoutines !== undefined && this.maxRoutines < this.selectedRoutineIds.length;
  }
}
