import dayjs from 'dayjs';
import { WeightUnit, BodyMeasurementUnit, BodyMeasurement } from 'hevy-shared';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';
import { ClientMeasurement, ClientMeasurementKey, EmptyClientMeasurement } from './types';
import toast from 'react-hot-toast';
import { makeAutoObservable } from 'mobx';
import {
  getClientMeasurement,
  getPostableBodyMeasurement,
  isAtLeastOneMeasurementValid,
  isValidMeasurementInput,
} from './utils';
import API from 'utils/API';
import { localStorageStores } from 'state/localStorageStores';
import { uploadImage } from 'utils/uploadMedia';
import { getUrlFromFile } from 'utils/imageUtils';
import { v4 as uuid } from 'uuid';
import { stringToMaybeNumber } from 'utils/pureUtils';

export class LogMeasurementViewModel {
  isSaving = false;
  clientId: string;
  measurementHistory: BodyMeasurement[];
  onMeasurementSaved: (newMeasurement: ClientMeasurement) => void;
  bodyMeasurement: ClientMeasurement = EmptyClientMeasurement;

  imageUrlToCrop: string | undefined;
  isUploadingImage = false;

  isChanged = false;

  constructor(
    clientId: string,
    measurementHistory: BodyMeasurement[],
    onMeasurementSaved: (newMeasurement: ClientMeasurement) => void,
  ) {
    this.clientId = clientId;
    this.measurementHistory = measurementHistory;
    this.onMeasurementSaved = onMeasurementSaved;
    this.onDateChange(this.bodyMeasurement.date);
    makeAutoObservable(this);
  }

  /**
   * Units
   */

  get weightUnit(): WeightUnit {
    return CoachUnitsAggregator.weightUnit;
  }

  get measurementUnit(): BodyMeasurementUnit {
    return CoachUnitsAggregator.bodyMeasurementUnit;
  }

  get bodyFatUnit() {
    return '%';
  }

  get firstWeekday() {
    return localStorageStores.userPreferences.firstWeekday;
  }

  get existingMeasurementDates() {
    return this.measurementHistory.map(measurement => measurement.date);
  }

  get isEmpty() {
    return !isAtLeastOneMeasurementValid(this.bodyMeasurement);
  }

  onMeasurementChange = (key: ClientMeasurementKey, value: string) => {
    if (!isValidMeasurementInput(value)) return;

    this.isChanged = true;

    this.bodyMeasurement[key] = stringToMaybeNumber(value);
  };

  onSaveClick = async () => {
    this.isSaving = true;
    const updateMeasurements = getPostableBodyMeasurement(
      this.bodyMeasurement,
      this.measurementUnit,
      this.weightUnit,
    );

    // Should never happen since the button is disabled when there are no measurements
    if (!updateMeasurements) {
      toast.error('No measurements to save');
      this.isSaving = false;
      return;
    }

    try {
      await API.postClientsBodyMeasurements(updateMeasurements, this.clientId);
      this.onMeasurementSaved(this.bodyMeasurement);
      toast.success('Measurement saved');
    } catch (error) {
      toast.error('Unable to save body measurement');
      throw error;
    } finally {
      this.isSaving = false;
    }
  };

  onDateChange = (newValue: string) => {
    const newDate = dayjs(newValue);

    this.isChanged = false;

    const matchingMeasurement = this.measurementHistory.find(measurement =>
      dayjs(measurement.date).isSame(newDate, 'day'),
    );

    if (matchingMeasurement) {
      this.bodyMeasurement = getClientMeasurement(
        matchingMeasurement,
        this.measurementUnit,
        this.weightUnit,
      );
    } else {
      this.bodyMeasurement = {
        ...EmptyClientMeasurement,
        date: newDate.format('YYYY-MM-DD'),
      };
    }
  };

  onDismissImageCrop = () => {
    this.isUploadingImage = false;
    this.imageUrlToCrop = undefined;
  };

  onUploadProgressPicture = async (imageUrl: string) => {
    this.imageUrlToCrop = undefined;
    try {
      const fileName = `${localStorageStores.account.username}-${uuid()}`;
      const { url } = await uploadImage(fileName, imageUrl);

      this.bodyMeasurement.pictureUrl = url;
      this.isChanged = true;
    } catch (e) {
      toast.error('Failed to upload picture');
    } finally {
      this.isUploadingImage = false;
    }
  };

  onFileSystemImageSelected = async (file: File) => {
    this.isUploadingImage = true;
    const { url } = await getUrlFromFile(file);
    this.imageUrlToCrop = url;
  };

  onDeletePicture = () => {
    this.bodyMeasurement.pictureUrl = undefined;
    this.isChanged = true;
  };
}
