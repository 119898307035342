import router from 'next/router';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { sendEvent } from './analyticsEvents';
import { HevyWebsocketClient } from './globals/HevyWebsocketClient';
import { dashboard } from 'utils/globals/dashboard';
import { fireAndForget } from './async';
import { PushAggregator } from 'state/aggregators/pushAggregator';
import { ServerStorage } from 'state/ServerStorage';

export const clearDataStores = () => {
  localStorageStores.account.clearData();
  localStorageStores.userPreferences.clearData();
  ServerStorage.clearData();
  memoryStores.clients.clearData();
  memoryStores.myLibraryRoutines.clearData();
  memoryStores.workouts.clearData();
  dashboard.clearData();
  localStorageStores.chats.clearData();
  localStorageStores.myPrograms.clearData();
  localStorageStores.programFolders.clearData();
  localStorageStores.exerciseTemplates.clearData();
  localStorageStores.team.clearData();
  localStorageStores.invites.clearData();
  localStorageStores.teamInvites.clearData();
  localStorageStores.whatsNew.clearData();
  localStorageStores.betaFeatures.clearData();
  localStorageStores.coachConfig.clearData();
  localStorageStores.clientLeads.clearData();
  HevyWebsocketClient.disconnect();
};

export const logout = async (source: string) => {
  sendEvent('logout', { source: source });
  console.info('🔓 Logging out...');

  await fireAndForget([PushAggregator.unregisterPush()]);

  localStorageStores.auth.logout();
  router.push('/login').then(() => {
    clearDataStores();
  });
};
