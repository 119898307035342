import { UserValue } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';
import API from 'utils/API';
import { fireAndForget } from 'utils/async';

const SERVER_STORAGE_DISK_CACHE_KEY = 'SERVER_STORAGE_CACHE';
const STORAGE_UPDATED_AT_KEY = `UPDATED_AT`;

class ServerStorageClass {
  cache: Record<string, UserValue> = {};

  constructor() {
    makeAutoObservable(this);
  }

  get = (key: string) => {
    const value = this.cache[key];

    return value;
  };

  set = async (key: string, value: UserValue) => {
    this.cache[key] = value;
    this.cache[STORAGE_UPDATED_AT_KEY] = dayjs().toISOString();
    const jsonCache = this.cache;

    window.localStorage.setItem(SERVER_STORAGE_DISK_CACHE_KEY, JSON.stringify(jsonCache));

    await API.updateUserKeyValues(jsonCache);
  };

  async hydrateData() {
    const cachedServerStorage = window.localStorage.getItem(SERVER_STORAGE_DISK_CACHE_KEY);

    if (cachedServerStorage) {
      this.cache = JSON.parse(cachedServerStorage);
    }
  }

  async fetch() {
    const response = await API.getUserKeyValues();

    const serverKeyValues = response.data;

    const cacheUpdatedAt = dayjs(String(this.cache[STORAGE_UPDATED_AT_KEY]));
    const serverStorageUpdatedAt = dayjs(String(serverKeyValues[STORAGE_UPDATED_AT_KEY]));

    if (cacheUpdatedAt.isAfter(serverStorageUpdatedAt)) {
      /**
       * this is only true when comparing two valid dates, so we are 100% sure
       * that the server storage is outdated due to a past failed request.
       *
       * we can thus safely update the server storage with the local values.
       */
      fireAndForget([API.updateUserKeyValues(this.cache)]);
    } else {
      this.cache = serverKeyValues;
      window.localStorage.setItem(SERVER_STORAGE_DISK_CACHE_KEY, JSON.stringify(this.cache));
    }
  }

  async clearData() {
    this.cache = {};
    window.localStorage.removeItem(SERVER_STORAGE_DISK_CACHE_KEY);
  }
}

export const ServerStorage = new ServerStorageClass();
