import React, { useState } from 'react';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { getCroppedImgJpeg } from 'utils/imageUtils';
import { Spacing } from 'styleguide/spacing';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CropContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: ${Spacing.md}px;
  gap: ${Spacing.sm}px;
  max-height: 50px;
`;

interface CropImageProps {
  imageUrl?: string;
  onImageCropped: (url: string) => void;
  onCancel: () => void;
  aspect?: number;
}

export const CropImage = ({ imageUrl, onImageCropped, onCancel, aspect }: CropImageProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [pixelCrop, setPixelCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });

  return (
    <Container>
      <CropContainer>
        <Cropper
          image={imageUrl}
          crop={crop}
          zoom={zoom}
          aspect={aspect ?? 1}
          showGrid={false}
          onCropChange={setCrop}
          onCropComplete={(_, pixelCrop) => setPixelCrop(pixelCrop)}
          onZoomChange={setZoom}
        />
      </CropContainer>
      <SaveButtonContainer>
        <SecondaryButton style={{ width: 200 }} title="Cancel" onClick={onCancel} />
        <PrimaryButton
          style={{ width: 200 }}
          title="Save"
          onClick={async () => {
            if (!imageUrl) return;
            const croppedImageUrl = await getCroppedImgJpeg(imageUrl, pixelCrop);
            onImageCropped(croppedImageUrl);
          }}
        />
      </SaveButtonContainer>
    </Container>
  );
};
