import React from 'react';
import styled from 'styled-components';
import { Modal } from 'styleguide/Modal';
import { View } from 'styleguide/View';
import { ModalHeader } from './Components/ModalHeader';
import { Spacing } from 'styleguide/spacing';
import { TextMDRegular, TextSMRegular } from 'styleguide/Texts';
import { FlexRow } from 'styleguide/Row';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { colors } from 'styleguide/colors';
import { CardNoPadding } from 'styleguide/Card';
import { HideOnMobileDiv } from 'components/mobileVsDesktopShowHideHelpers';
import { DividerLine } from 'styleguide/DividerLine';
import DownloadAppstoreButton from 'components/assets/download-appstore.svg';
import DownloadGooglePlayButton from 'components/assets/download-googleplay.svg';
import DownloadQRCode from 'components/assets/mobile-app-qr-code.png';
import { CircleView } from 'styleguide/CircleView';
import { sendEvent } from 'utils/analyticsEvents';
import Image from 'next/image';
import { appStoreUrl, playStoreUrl } from 'utils/constants';
import { useDeviceSize } from 'utils/useSize';

const Container = styled(View)`
  flex: 1;
  max-width: 970px;
  @media (max-width: 1024px) {
    max-width: 380px;
  }
`;

const MiddleContainer = styled(View)`
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const DescriptionContainer = styled(View)`
  justify-content: space-between;
  gap: ${Spacing.md}px;
  padding: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.md}px;
  }
`;

const MobileHevyCoachAssetContainer = styled(View)`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    background-color: ${colors.neutral100};
  }
`;

export interface HevyCoachAppModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const HevyCoachAppModal = ({ isOpen, onClose }: HevyCoachAppModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <ModalHeader text="🥳 Introducing Hevy Coach Mobile!" onClose={onClose} />
        <DividerLine />
        <MiddleContainer>
          <MobileHevyCoachAssetContainer>
            <img
              style={{ width: 320, aspectRatio: 1.715 }}
              src={'/images/hevy-coach-app-mobile.png'}
            />
          </MobileHevyCoachAssetContainer>

          <DescriptionContainer>
            <View style={{ gap: Spacing.md }}>
              <TextMDRegular>
                We're excited to introduce our new mobile app, making it easier to log your clients'
                workouts and chat on the go. Get it now on the App Store or Google Play.
              </TextMDRegular>

              <ChecklistItem text="Log 1:1 coaching sessions" />
              <ChecklistItem text="Chat with your clients" />
              <ChecklistItem text="Access client details" />
            </View>

            <CtaCard />
          </DescriptionContainer>

          <HideOnMobileDiv
            style={{
              padding: Spacing.lg,
              backgroundColor: colors.neutral100,
              alignContent: 'center',
            }}
          >
            <img
              style={{ width: 330, aspectRatio: 0.85649203 }}
              src={'/images/hevy-coach-app-desktop.png'}
            />
          </HideOnMobileDiv>
        </MiddleContainer>
      </Container>
    </Modal>
  );
};

const ChecklistItem = ({ text }: { text: string }) => {
  return (
    <FlexRow style={{ gap: Spacing.md }}>
      <CircleView
        diameter={24}
        style={{
          backgroundColor: colors.neutral900,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CustomizableIcon type={'check'} tint={colors.white} />
      </CircleView>
      <TextMDRegular>{text}</TextMDRegular>
    </FlexRow>
  );
};

const CtaCard = () => {
  const qrCodeSize = 143;
  const isOnDesktop = useDeviceSize() === 'desktop';
  const isOnPhone = useDeviceSize() === 'phone';
  return (
    <CardNoPadding style={{ marginBottom: 0, padding: Spacing.md }}>
      <FlexRow style={{ gap: Spacing.md, justifyContent: 'space-between' }}>
        <View>
          <TextMDRegular>Get the App Now</TextMDRegular>
          <TextSMRegular style={{ marginTop: Spacing.xs }}>
            Visit the App Store, Google Play, or scan QR code with your phone to download the app.
          </TextSMRegular>
          <FlexRow
            style={{
              gap: Spacing.sm,
              marginTop: Spacing.md,
              justifyContent: isOnDesktop ? undefined : 'center',
              flexWrap: isOnPhone ? 'wrap' : undefined,
            }}
          >
            <a
              href={appStoreUrl}
              onClick={() => sendEvent('hevyCoachAppModal_click_download_appstore')}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadAppstoreButton />
            </a>
            <a
              href={playStoreUrl}
              onClick={() => sendEvent('hevyCoachAppModal_click_download_googleplay')}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadGooglePlayButton />
            </a>
          </FlexRow>
        </View>
        <HideOnMobileDiv>
          <View style={{ alignSelf: 'flex-start', paddingLeft: Spacing.lg }}>
            <Image
              style={{ width: qrCodeSize, height: qrCodeSize }}
              src={DownloadQRCode}
              alt={'Download QR'}
            />
          </View>
        </HideOnMobileDiv>
      </FlexRow>
    </CardNoPadding>
  );
};
