import { HideOnPhoneDiv, ShowOnPhoneDiv } from 'components/mobileVsDesktopShowHideHelpers';
import { ProfileImage } from 'components/ProfileImage';
import { Program } from 'hevy-shared';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { memoryStores } from 'state/memoryStores';
import styled from 'styled-components';
import { ClickableCard } from 'styleguide/ClickableCard';
import { colors } from 'styleguide/colors';
import { StyledRadioButton, StyledCheckbox } from 'styleguide/Inputs';
import { FlexRow } from 'styleguide/Row';
import { Spacing } from 'styleguide/spacing';
import { TextPill } from 'styleguide/TextPill';
import { TextMD, TextSMRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { ClientProgram } from 'utils/programUtils';
import { SmallHevyActivityIndicator } from './HevyActivityIndicator';
import { ProgramScheduleText } from './ProgramScheduling/ProgramScheduleText';

const SelectionCard = styled(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  padding: ${Spacing.lg}px;
  @media (max-width: 1024px) {
    padding: ${Spacing.md}px;
  }
`;

const ProgramTitleText = styled(TextMD)`
  text-align: left;
`;

interface ProgramSelectionCardProps {
  program: Program;
  onSelectProgram: (id: string) => void;
  isSelected: boolean;
  checkbox?: boolean;
}

export const ProgramSelectionCard = observer(
  ({ program, onSelectProgram, isSelected, checkbox }: ProgramSelectionCardProps) => {
    return (
      <ClickableCard style={{ padding: 0 }} onClick={() => onSelectProgram(program.id)}>
        <SelectionCard>
          <View style={{ gap: Spacing.sm }}>
            <FlexRow style={{ gap: Spacing.sm }}>
              {!!checkbox ? (
                <StyledCheckbox checked={isSelected} onChange={() => onSelectProgram(program.id)} />
              ) : (
                <StyledRadioButton checked={isSelected} />
              )}
              <View style={{ justifyContent: 'center', gap: Spacing.xs }}>
                <ProgramTitleText>{program.title}</ProgramTitleText>
                <HideOnPhoneDiv>
                  <View>
                    {memoryStores.myLibraryRoutines.isFetching ? (
                      <SmallHevyActivityIndicator />
                    ) : (
                      <FlexRow style={{ gap: Spacing.sm, flexWrap: 'wrap' }}>
                        {memoryStores.myLibraryRoutines
                          .sortedRoutinesForProgram(program.id)
                          .map(routine => (
                            <TextPill
                              key={routine.id}
                              text={routine.title}
                              backgroundColor={colors.neutral100}
                              textColor={colors.neutral500}
                            ></TextPill>
                          ))}
                      </FlexRow>
                    )}
                  </View>
                </HideOnPhoneDiv>
              </View>
            </FlexRow>
            <ShowOnPhoneDiv>
              {memoryStores.myLibraryRoutines.isFetching ? (
                <SmallHevyActivityIndicator />
              ) : (
                <FlexRow style={{ gap: Spacing.sm, flexWrap: 'wrap' }}>
                  {memoryStores.myLibraryRoutines
                    .sortedRoutinesForProgram(program.id)
                    .map(routine => (
                      <TextPill
                        key={routine.id}
                        text={routine.title}
                        backgroundColor={colors.neutral100}
                        textColor={colors.neutral500}
                      ></TextPill>
                    ))}
                </FlexRow>
              )}
            </ShowOnPhoneDiv>
          </View>
          {!!program.duration_days && (
            <ProgramScheduleText
              durationDays={program.duration_days}
              startDate={program.start_date}
            />
          )}
        </SelectionCard>
      </ClickableCard>
    );
  },
);

interface ClientProgramSelectionCardProps {
  program: ClientProgram;
  onSelectProgram: (id: string) => void;
  isSelected: boolean;
}

export const ClientProgramSelectionCard = observer(
  ({ program, onSelectProgram, isSelected }: ClientProgramSelectionCardProps) => {
    const client = memoryStores.clients.clientForUserId(program.client_id);
    if (!client) {
      return null;
    }

    return (
      <ClickableCard style={{ padding: 0 }} onClick={() => onSelectProgram(program.id)}>
        <SelectionCard>
          <View style={{ gap: Spacing.sm }}>
            <FlexRow style={{ gap: Spacing.sm }}>
              <StyledCheckbox checked={isSelected} onChange={() => onSelectProgram(program.id)} />
              <View style={{ alignItems: 'flex-start', justifyContent: 'center', gap: Spacing.xs }}>
                <FlexRow style={{ gap: Spacing.sm }}>
                  <ProfileImage source={client.profilePic} diameter={20} />
                  <TextSMRegular>{client.fullName || client.username}</TextSMRegular>
                </FlexRow>
                <ProgramTitleText>{program.title}</ProgramTitleText>
                {!!program.duration_days && (
                  <ProgramScheduleText
                    durationDays={program.duration_days}
                    startDate={program.start_date}
                  />
                )}
              </View>
            </FlexRow>
          </View>
        </SelectionCard>
      </ClickableCard>
    );
  },
);
