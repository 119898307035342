import { toJS } from 'mobx';
import { Clients } from './memoryStores/clients';
import { MyLibraryRoutines } from './memoryStores/myLibraryRoutines';
import { Workouts } from './memoryStores/workouts';

// These stores are not persisted to local storage as they can easily be too big to fit
export const memoryStores = {
  clients: new Clients(),
  myLibraryRoutines: new MyLibraryRoutines(),
  workouts: new Workouts(),
};

if (process.env.NEXT_PUBLIC_ENV !== 'production') {
  (global as any).stores = memoryStores;
  (global as any).toJS = toJS;
}
