import { CloseButton } from 'components/CloseButton';
import { Calendar } from 'components/Calendar';
import { ProgramDurationSelector } from 'components/ProgramScheduling/ProgramDurationSelector';
import React, { useState } from 'react';
import { localStorageStores } from 'state/localStorageStores';
import styled from 'styled-components';
import { PrimaryButton } from 'styleguide/Buttons';
import { Modal } from 'styleguide/Modal';
import { FlexRow } from 'styleguide/Row';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { TextLG, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';

const Container = styled(View)`
  border-radius: ${CornerRadius.md}px;
  padding: ${Spacing.lg}px;
  gap: ${Spacing.md}px;
`;

const UpperContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const MiddleContainer = styled(View)`
  gap: ${Spacing.md}px;
`;

const LowerContainer = styled(View)`
  gap: ${Spacing.md}px;
`;

export interface ProgramScheduleModalProps {
  isOpen: boolean;
  startDate: string | null;
  durationDays: number | null;
  onStartDateChange: (date: string | null) => void;
  onDurationChange: (duration: number | null) => void;
  onClose: () => void;
}

export const ProgramScheduleModal = (props: ProgramScheduleModalProps) => {
  const { isOpen, onClose, onDurationChange, onStartDateChange } = props;

  const [durationDays, setDurationDays] = useState<number | null>(props.durationDays);
  const [startDate, setStartDate] = useState<string | null>(props.startDate);

  const handleSave = () => {
    onDurationChange(durationDays);
    onStartDateChange(startDate);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <UpperContainer>
          <TextLG style={{ marginBottom: Spacing.xs }}>Program Schedule</TextLG>
          <CloseButton onClick={onClose} />
        </UpperContainer>
        <MiddleContainer>
          <View style={{ gap: Spacing.sm }}>
            <TextSMMedium>Duration</TextSMMedium>
            <ProgramDurationSelector
              selectedDuration={durationDays}
              onSelectDuration={setDurationDays}
            ></ProgramDurationSelector>
          </View>
          <View style={{ gap: Spacing.sm }}>
            <TextSMMedium>Start Date</TextSMMedium>
            <Calendar
              durationDays={durationDays}
              selectedDate={startDate}
              onSelectDate={setStartDate}
              firstWeekday={localStorageStores.coachConfig.firstWeekday}
            ></Calendar>
          </View>
        </MiddleContainer>
        <LowerContainer>
          <PrimaryButton title="Done" onClick={handleSave} />
        </LowerContainer>
      </Container>
    </Modal>
  );
};
