import { Program } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { Client } from 'types/client';

interface NavBarSearchClientResult {
  type: 'client';
  client: Client;
}
interface NavBarSearchProgramResult {
  type: 'program';
  program: Program;
}

export type NavBarSearchResult = NavBarSearchProgramResult | NavBarSearchClientResult;

export class NavBarUserSearchViewModel {
  searchString = '';
  results: NavBarSearchResult[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get showMenu() {
    return this.searchString.length > 1;
  }

  onSearchStringChanged = (newValue: string) => {
    this.searchString = newValue;
    if (this.showMenu) {
      this.updateResults();
    }
  };

  updateResults = () => {
    const clients = memoryStores.clients.allClients.filter(client => {
      return (
        client.username.toLowerCase().includes(this.searchString.toLowerCase()) ||
        (client.fullName ?? '').toLowerCase().includes(this.searchString.toLowerCase()) ||
        (client.email ?? '').toLowerCase().includes(this.searchString.toLowerCase())
      );
    });
    const programs = localStorageStores.myPrograms.templates.filter(program => {
      return program.title.toLowerCase().includes(this.searchString.toLowerCase());
    });

    let results: NavBarSearchResult[] = [];

    results = results.concat(
      clients.map(client => {
        return { type: 'client', client } as NavBarSearchClientResult;
      }),
    );
    results = results.concat(
      programs.map(program => {
        return { type: 'program', program };
      }),
    );

    results.sort((a, b) => {
      const aText = a.type === 'client' ? a.client.username : a.program.title;
      const bText = b.type === 'client' ? b.client.username : b.program.title;
      return aText.localeCompare(bText);
    });

    this.results = results;
  };
}
