import React from 'react';
import { Modal } from 'styleguide/Modal';
import { ModalHeader } from '../Components/ModalHeader';
import { LogMeasurementViewModel } from './LogMeasurementVIewModel';
import styled from 'styled-components';
import { View } from 'styleguide/View';
import { FlexRow } from 'styleguide/Row';
import { DividerLine } from 'styleguide/DividerLine';
import { CornerRadius, Spacing } from 'styleguide/spacing';
import { PrimaryButton, SecondaryButton } from 'styleguide/Buttons';
import { observer } from 'mobx-react-lite';
import { MeasurementInputRow } from './components/MeasurementInputRow';
import { clientMeasurementsList } from './types';
import { TextSMMedium } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { DateSelector } from './components/DateSelector';
import { CropImageModal } from '../CropImage/CropImageModal';
import { MediumHevyActivityIndicator } from 'components/HevyActivityIndicator';
import { HevyMenu } from 'components/HevyMenu/HevyMenu';
import { HevyMenuItem } from 'components/HevyMenu/HevyMenuItem';

const Container = styled(View)`
  width: 684px;
`;

const MiddleContainer = styled(View)`
  padding: ${Spacing.md}px ${Spacing.lg}px;
  overflow-y: scroll;
`;

const BottomContainer = styled(FlexRow)`
  flex: 1;
  justify-content: flex-end;
  gap: ${Spacing.md}px;
  padding: ${Spacing.md}px ${Spacing.lg}px;
`;

const UploadImageContainer = styled.label`
  gap: ${Spacing.sm}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${CornerRadius.sm}px;
  border: 1.2px dashed ${colors.neutral300};
  background-color: ${colors.white};
  height: 88px;
  cursor: pointer;
`;

const Picture = styled.img`
  aspect-ratio: 3/4;
  border-radius: ${CornerRadius.md}px;
  width: 120px;
  cursor: pointer;
`;

export interface LogMeasurementModalProps {
  isOpen: boolean;
  vm: LogMeasurementViewModel;
  onClose: () => void;
}

export const LogMeasurementModal = observer(({ isOpen, vm, onClose }: LogMeasurementModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <ModalHeader text={'Log Client Measurement'} onClose={onClose} />

        <DividerLine />

        <MiddleContainer>
          <FlexRow
            style={{
              justifyContent: 'space-between',
              paddingTop: Spacing.md,
              paddingBottom: Spacing.md,
            }}
          >
            <TextSMMedium>Date</TextSMMedium>

            <DateSelector
              selectedDate={vm.bodyMeasurement.date}
              fisrtWeekday={vm.firstWeekday}
              onSelectDate={vm.onDateChange}
              markedDates={vm.existingMeasurementDates}
            />
          </FlexRow>

          <View style={{ gap: Spacing.md }}>
            <TextSMMedium>Progress Picture</TextSMMedium>
            {vm.bodyMeasurement.pictureUrl ? (
              <HevyMenu
                arrow={false}
                portal={false}
                direction="left"
                menuButton={<Picture src={vm.bodyMeasurement.pictureUrl} />}
              >
                <HevyMenuItem
                  onClick={vm.onDeletePicture}
                  title="Delete"
                  customizableIconType="trash"
                  tint={colors.error500}
                  textStyle={{ color: colors.error500 }}
                ></HevyMenuItem>
              </HevyMenu>
            ) : (
              <UploadImageContainer>
                <input
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={e => {
                    const file = e.target?.files?.[0];
                    if (file) {
                      vm.onFileSystemImageSelected(file);
                    }
                  }}
                />
                {vm.isUploadingImage ? (
                  <MediumHevyActivityIndicator />
                ) : (
                  <>
                    <CustomizableIcon type="picture-plus" tint={colors.primary500} />
                    <TextSMMedium style={{ color: colors.primary500 }}>Upload Image</TextSMMedium>
                  </>
                )}
              </UploadImageContainer>
            )}
          </View>

          <DividerLine />

          {clientMeasurementsList.map(measurementKey => (
            <MeasurementInputRow
              key={measurementKey}
              unit={
                measurementKey === 'weight'
                  ? vm.weightUnit
                  : measurementKey === 'bodyfat'
                  ? '%'
                  : vm.measurementUnit
              }
              value={vm.bodyMeasurement[measurementKey]?.toString() || ''}
              onChange={vm.onMeasurementChange}
              measurementKey={measurementKey}
            />
          ))}
        </MiddleContainer>

        <DividerLine />

        <BottomContainer>
          <SecondaryButton onClick={onClose} title="Cancel" />
          <PrimaryButton
            title="Save Measurement"
            onClick={async () => {
              await vm.onSaveClick();
              onClose();
            }}
            loading={vm.isSaving || vm.isUploadingImage}
            enabled={vm.isChanged && !vm.isSaving && !vm.isEmpty && !vm.isUploadingImage}
          />
        </BottomContainer>
      </Container>
      <CropImageModal
        imageUrl={vm.imageUrlToCrop}
        onClose={vm.onDismissImageCrop}
        onImageCropped={vm.onUploadProgressPicture}
        aspect={3 / 4}
      />
    </Modal>
  );
});
