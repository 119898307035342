import { Calendar } from 'components/Calendar/Calendar';
import dayjs from 'dayjs';
import { Weekday } from 'hevy-shared';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties } from 'react';
import RSelect, { MenuProps, components, GroupBase, Props } from 'react-select';
import styled from 'styled-components';
import { PHONE_WIDTH_BREAKPOINT } from 'styleguide/Breakpoints';
import { CardNoPadding } from 'styleguide/Card';
import { colors } from 'styleguide/colors';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import fonts from 'styleguide/fonts';
import { FlexRow } from 'styleguide/Row';
import { Spacing, CornerRadius } from 'styleguide/spacing';

const Container = styled(FlexRow)`
  width: 240px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    width: 180px;
  }
`;

interface DateSelectorProps {
  selectedDate: string;
  fisrtWeekday: Weekday;
  onSelectDate: (date: string) => void;
  markedDates?: string[];
}

export const DateSelector = ({
  selectedDate,
  fisrtWeekday,
  onSelectDate,
  markedDates,
}: DateSelectorProps) => {
  return (
    <Container>
      <Selector
        date={selectedDate}
        onDateChange={date => {
          if (date) onSelectDate(date);
        }}
        firstWeekday={fisrtWeekday}
        markedDates={markedDates}
      />
    </Container>
  );
};

interface DateOption {
  label: string;
  value: string | null; // Date
}

const getDateOption = (date: string | null): DateOption => {
  return {
    label: dayjs(date).format('MMM D, YYYY'),
    value: date ?? dayjs().format('YYYY-MM-DD'), // Default to today
  };
};

const PopUpContainer = styled(CardNoPadding)`
  position: absolute;
  right: 0;
  z-index: 1006;
  box-shadow: 0px 4px 16px -2px rgba(16, 24, 40, 0.1);
  display: flex;
  flex-direction: column;
  padding: ${Spacing.lg}px;
  @media (max-width: ${PHONE_WIDTH_BREAKPOINT}px) {
    padding: ${Spacing.md}px;
  }
`;

interface SelectorViewProps {
  date: string | null;
  firstWeekday: Weekday;
  markedDates?: string[];
}

const CalendarPopUp = (props: MenuProps<DateOption, false> & SelectorViewProps) => {
  const { date, firstWeekday, markedDates } = props;

  return (
    <components.Menu {...props}>
      <PopUpContainer>
        <Calendar
          selectedDate={date}
          onSelectDate={date => props.setValue(getDateOption(date), 'select-option')}
          firstWeekday={firstWeekday}
          isFutureDatesDisabled={true}
          markedDates={markedDates}
        />
      </PopUpContainer>
    </components.Menu>
  );
};

type SelectProps = Props<DateOption, false, GroupBase<DateOption>>;

interface SelectorProps extends SelectProps {
  date: string;
  backgroundColor?: string;
  onDateChange: (date: string | null) => void;
  firstWeekday: Weekday;
  markedDates?: string[];
}

const Selector = observer((props: SelectorProps) => {
  const { date, firstWeekday, markedDates } = props;

  return (
    <RSelect
      menuPosition="absolute"
      backgroundColor={colors.white}
      value={{
        label: dayjs(date).format('MMM D, YYYY'),
        value: date,
      }}
      onChange={newValue => {
        props.onDateChange(newValue?.value ?? dayjs().format('YYYY-MM-DD')); // Default to today
      }}
      isSearchable={false}
      styles={{
        container: styles => ({
          ...styles,
          ...{ flex: 1 },
        }),
        placeholder: styles => ({
          ...styles,
          ...{ justifySelf: 'center', color: colors.neutral400 },
        }),
        control: styles => ({
          ...styles,
          ...controlStyle,
        }),
        valueContainer: styles => ({
          ...styles,
          ...{ justifySelf: 'center', padding: 0 },
        }),
        singleValue: styles => ({
          ...styles,
          ...{ color: colors.neutral900, marginLeft: Spacing.md },
        }),
        indicatorSeparator: styles => ({ ...styles, ...{ backgroundColor: 'transparent' } }),
        dropdownIndicator: styles => ({
          ...styles,
          ...{ position: 'absolute', right: 0 },
          ...{ pointerEvents: 'none' },
        }),
      }}
      components={{
        Menu: props => {
          return (
            <CalendarPopUp
              {...props}
              date={date}
              firstWeekday={firstWeekday}
              markedDates={markedDates}
            />
          );
        },
        DropdownIndicator: props => {
          return (
            <components.DropdownIndicator {...props}>
              <CustomizableIcon type="calendar" tint={colors.neutral900}></CustomizableIcon>
            </components.DropdownIndicator>
          );
        },
      }}
      {...props}
    />
  );
});

const controlStyle: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  height: '40px',
  minHeight: 'auto',
  borderRadius: CornerRadius.sm,
  borderColor: colors.neutral200,
  backgroundColor: colors.white,
  fontFamily: fonts.regular.family,
  fontWeight: fonts.regular.weight,
  fontSize: '14px',
  cursor: 'pointer',
  position: 'relative',
};
