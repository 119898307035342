import { reaction } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { setAmplitudeUserProps } from './amplitude';
import * as Sentry from '@sentry/nextjs';
import { memoryStores } from 'state/memoryStores';
import { setHotjarIdentity } from './hotjar';
import { setIntercomIdentity } from './intercom';
import { logProfitWellEmail } from './profitWell';
import { CoachRole } from 'hevy-shared';

export interface AnalyticsUserProperties {
  username: string;
  fullName?: string;
  personalName: string;
  email: string;
  userId: string;
  // Clients of the entire team
  clientsCount: number;
  // Clients specifically of this coach
  directClientsCount: number;
  programTemplatesCount: number;
  assignedProgramsCount: number;
  programsAssignedToRealClientsCount: number;
  isPaidCoach: boolean;
  coachPaymentPlan: string;
  accountCreatedAt: string;
  becameCoachAt?: string;
  isTrialing: boolean;
  trialExpiryDate?: string;
  isCoachingTheirself: boolean;
  role: CoachRole;
  teamTitle: string;
}

const numberOfProgramsAssignedToRealClients = () => {
  return memoryStores.clients.nonSampleClients.filter(c => {
    return !!localStorageStores.myPrograms.programAssignedToClient(c.id);
  }).length;
};

export const userPropertiesListener = () => {
  reaction(
    (): AnalyticsUserProperties => {
      return {
        username: localStorageStores.account.username,
        fullName: localStorageStores.account.full_name,
        personalName: localStorageStores.account.personalCoachName,
        email: localStorageStores.account.email,
        userId: localStorageStores.account.id,
        clientsCount: memoryStores.clients.nonSampleClientsCount,
        directClientsCount: memoryStores.clients.clientsForCoachId(localStorageStores.account.id)
          .length,
        programTemplatesCount: localStorageStores.myPrograms.templates.length,
        assignedProgramsCount:
          localStorageStores.myPrograms.allPrograms.length -
          localStorageStores.myPrograms.templates.length,
        programsAssignedToRealClientsCount: numberOfProgramsAssignedToRealClients(),
        isPaidCoach: localStorageStores.plan.isPaidPaddlePlan ?? false,
        coachPaymentPlan: localStorageStores.plan.currentPlan?.title ?? 'Free',
        accountCreatedAt: localStorageStores.account.created_at,
        becameCoachAt: localStorageStores.account.became_coach_at,
        isTrialing: localStorageStores.plan.isTrialing,
        trialExpiryDate: localStorageStores.plan.isTrialing
          ? localStorageStores.plan.trialExpiryDate
          : undefined,
        isCoachingTheirself: memoryStores.clients.nonSampleClients.some(
          client => client.id === localStorageStores.account.id,
        ),
        role: localStorageStores.account.role,
        teamTitle: localStorageStores.team.title,
      };
    },
    (props: AnalyticsUserProperties, previousProps: AnalyticsUserProperties) => {
      if (localStorageStores.auth.isImpersonating) {
        return;
      }
      if (
        props.username !== previousProps.username ||
        props.fullName !== previousProps.fullName ||
        props.personalName !== previousProps.personalName ||
        props.userId !== previousProps.userId ||
        props.clientsCount !== previousProps.clientsCount ||
        props.directClientsCount !== previousProps.directClientsCount ||
        props.programTemplatesCount !== previousProps.programTemplatesCount ||
        props.assignedProgramsCount !== previousProps.assignedProgramsCount ||
        props.programsAssignedToRealClientsCount !==
          previousProps.programsAssignedToRealClientsCount ||
        props.isPaidCoach !== previousProps.isPaidCoach ||
        props.coachPaymentPlan !== previousProps.coachPaymentPlan ||
        props.accountCreatedAt !== previousProps.accountCreatedAt ||
        props.becameCoachAt !== previousProps.becameCoachAt ||
        props.isTrialing !== previousProps.isTrialing ||
        props.trialExpiryDate !== previousProps.trialExpiryDate ||
        props.isCoachingTheirself !== previousProps.isCoachingTheirself ||
        props.role !== previousProps.role ||
        props.teamTitle !== previousProps.teamTitle
      ) {
        setHotjarIdentity(props, props.userId);
        setAmplitudeUserProps(props);
        setIntercomIdentity(props);

        logProfitWellEmail(props.email);

        Sentry.setUser({
          username: props.username,
          id: props.userId,
          email: props.email,
        });
      }
    },
    { delay: 1000 },
  );
};
