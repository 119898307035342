import React from 'react';
import { colors } from 'styleguide/colors';
import DownloadAppstoreButton from 'components/assets/download-appstore.svg';
import DownloadGooglePlayButton from 'components/assets/download-googleplay.svg';
import DownloadQRCode from 'components/assets/mobile-app-qr-code.png';
import { TextMD } from 'styleguide/Texts';
import { Spacing } from 'styleguide/spacing';
import { HevyMenu } from './HevyMenu/HevyMenu';
import styled from 'styled-components';
import { MenuAlign } from '@szhsin/react-menu';
import { View } from 'styleguide/View';
import { FlexRow } from 'styleguide/Row';
import { sendEvent } from 'utils/analyticsEvents';
import Image from 'next/image';
import { appStoreUrl, playStoreUrl } from 'utils/constants';

const PopUpContainer = styled(View)`
  max-width: 276px;
  gap: ${Spacing.sm}px;
`;

const Downloads = styled(FlexRow)`
  gap: ${Spacing.md}px;
`;

const QRCodeContainer = styled.div`
  padding: 14px;
  border-radius: 7.147px;
  outline: 1px solid #e5e7eb;
`;

type MobileAppPopupProps = {
  title?: string;
  align: MenuAlign;
  renderButton: () => JSX.Element;
  source: string;
};

export const MobileAppPopup = ({ title, align, renderButton, source }: MobileAppPopupProps) => {
  return (
    <HevyMenu
      arrow={false}
      align={align}
      menuButton={renderButton}
      onItemClick={e => {
        e.syntheticEvent.stopPropagation();
        e.syntheticEvent.preventDefault();
      }}
      menuStyle={{
        border: `1px solid ${colors.neutral150}`,
        backgroundColor: colors.white,
        padding: Spacing.md,
        borderRadius: 10,
        boxShadow:
          '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PopUpContainer>
        {title && <TextMD style={{ lineHeight: '20px' }}>{title}</TextMD>}
        <Downloads>
          <QRCodeContainer>
            <Image
              style={{ width: '100%', height: '100%' }}
              src={DownloadQRCode}
              alt={'Download QR'}
            />
          </QRCodeContainer>
          <View style={{ gap: Spacing.sm }}>
            <a
              href={appStoreUrl}
              onClick={() => sendEvent('mobileAppPopUp_click_download_appstore', { source })}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadAppstoreButton />
            </a>
            <a
              href={playStoreUrl}
              onClick={() => sendEvent('mobileAppPopUp_click_download_googleplay', { source })}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadGooglePlayButton />
            </a>
          </View>
        </Downloads>
      </PopUpContainer>
    </HevyMenu>
  );
};
