import dayjs from 'dayjs';
import { makeAutoObservable } from 'mobx';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';
import { WeeklyClientData } from '../../screens/Dashboard/types';
import { ClientActivity, WeeklyActiveClients } from 'hevy-shared';
import toast from 'react-hot-toast';
import { fireAndForget } from 'utils/async';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';

export class Dashboard {
  latestActivities: ClientActivity[] = [];
  oldestActivityIndex?: number = undefined;
  isLoadingActivities = false;

  weeklyActiveClients: WeeklyActiveClients[] = [];
  isLoadingWeeklyActiveClients = false;
  filteredCoachIds: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  clearData = () => {
    this.latestActivities = [];
    this.oldestActivityIndex = undefined;
    this.isLoadingActivities = false;
    this.weeklyActiveClients = [];
    this.isLoadingWeeklyActiveClients = false;
    this.filteredCoachIds = [];
  };

  refresh = async () => {
    fireAndForget([
      this.fetchWeeklyActiveClients(),
      memoryStores.clients.fetchClientWorkoutHistory(),
    ]);

    this.filteredCoachIds = localStorageStores.team.members.map(m => m.id);

    // If there are already activites, they need
    // to scroll to the bottom to load more
    if (this.latestActivities.length === 0) {
      fireAndForget([this.fetchMoreClientActivities()]);
    }
  };

  fetchMoreClientActivities = async () => {
    this.isLoadingActivities = true;

    try {
      const targetCoaches =
        localStorageStores.account.role === 'member'
          ? undefined
          : this.filteredCoachIds.length > 0
          ? this.filteredCoachIds
          : undefined;
      const response = (
        await API.getClientsActivitiesPaged(this.oldestActivityIndex, targetCoaches)
      ).data;

      const activities = response.activities;
      this.oldestActivityIndex = response.oldestActivityIndex ?? this.oldestActivityIndex;

      this.latestActivities.push(...activities);
    } catch {
      // We expect this to fail if the plan is not active
      if (localStorageStores.plan.currentPlan?.status === 'active') {
        toast.error('Failed to fetch client activities');
      }
    } finally {
      this.isLoadingActivities = false;
    }
  };

  async fetchWeeklyActiveClients() {
    try {
      this.isLoadingWeeklyActiveClients = true;
      const response = await API.getWeeklyActiveClients();

      this.weeklyActiveClients = response.data;
    } catch {
      // We expect this to fail if the plan is not active
      if (localStorageStores.plan.currentPlan?.status === 'active') {
        toast.error('Failed to fetch weekly active clients');
      }
    } finally {
      this.isLoadingWeeklyActiveClients = false;
    }
  }

  get filteredLatestActivities() {
    return this.latestActivities.filter(activity =>
      this.filteredClients.map(c => c.id).includes(activity.client_id),
    );
  }

  get activeClientsData(): WeeklyClientData {
    return this.weeklyActiveClients.map(week => {
      const filteredActiveClientCount = week.active_client_ids.filter(clientId =>
        this.filteredClients.map(c => c.id).includes(clientId),
      ).length;

      const filteredInactiveClientCount = week.inactive_client_ids.filter(clientId =>
        this.filteredClients.map(c => c.id).includes(clientId),
      ).length;

      return {
        startOfWeekDate: week.week_start_date,
        value: filteredActiveClientCount,
        totalClientCount: filteredActiveClientCount + filteredInactiveClientCount,
      };
    });
  }

  get showTeamInfo() {
    return (
      localStorageStores.account.isAdminOrOwner && localStorageStores.team.hasMoreThanOneMember
    );
  }

  get teamMembers() {
    return localStorageStores.team.members;
  }

  get viewerCoach() {
    return localStorageStores.account;
  }

  get coachWeightUnit() {
    return CoachUnitsAggregator.weightUnit;
  }

  get filteredClients() {
    return memoryStores.clients.nonSampleClients.filter(client =>
      this.filteredCoachIds.includes(client.coachId),
    );
  }

  get filteredClientsCount() {
    return this.filteredClients.length;
  }

  get activeClientsLastSevenDays() {
    const weekStartDateUnix = dayjs().subtract(1, 'week').startOf('day').unix();

    return this.filteredClients.filter(
      client => client.lastWorkoutDate && dayjs(client.lastWorkoutDate).unix() >= weekStartDateUnix,
    );
  }

  get activeClientsCountLastSevenDays() {
    return this.activeClientsLastSevenDays.length;
  }

  get nonActiveClientsSevenDays() {
    const weekStartDateUnix = dayjs().subtract(1, 'week').startOf('day').unix();

    return this.filteredClients.filter(
      client => !client.lastWorkoutDate || dayjs(client.lastWorkoutDate).unix() < weekStartDateUnix,
    );
  }

  get nonActiveClientsCountLastSevenDays() {
    return this.nonActiveClientsSevenDays.length;
  }

  /** Onboarding tutorial related functions */
  get hasAssignedAWorkoutProgramToArealClient() {
    for (const program of localStorageStores.myPrograms.clientPrograms) {
      const client = memoryStores.clients.clientForUserId(program.client_id);
      if (!!client && !client.isSampleUser) {
        return true;
      }
    }

    return false;
  }

  get hasInvitedAClient() {
    return memoryStores.clients.clientsForCoachId(this.viewerCoach.id).length > 0;
  }

  get hasCreatedAWorkoutProgram() {
    return localStorageStores.myPrograms.templates.length > 0;
  }

  /**
   * If the user has invited a client, created a workout program, and assigned it to a client, then the tutorial is complete.
   */
  get isTutorialActive() {
    return !this.hasAssignedAWorkoutProgramToArealClient;
  }

  get isMyClientsFilterActive() {
    return (
      this.filteredCoachIds.includes(localStorageStores.account.id) &&
      this.filteredCoachIds.length === 1
    );
  }

  onMyClientsFilterClick = () => {
    if (this.isMyClientsFilterActive) {
      this.filteredCoachIds = localStorageStores.team.members.map(m => m.id);
    } else {
      this.filteredCoachIds = [localStorageStores.account.id];
    }
    this.onCoachFilterChanged();
  };

  onSelectCoachFilter = (coachId: string) => {
    if (this.filteredCoachIds.includes(coachId)) {
      this.filteredCoachIds = this.filteredCoachIds.filter(id => id !== coachId);
    } else {
      this.filteredCoachIds.push(coachId);
    }
    this.onCoachFilterChanged();
  };

  onSelectOneCoachFilter = (coachId: string) => {
    this.filteredCoachIds = [coachId];
    this.onCoachFilterChanged();
  };

  onSelectAllCoachesFilter = () => {
    this.filteredCoachIds = localStorageStores.team.members.map(m => m.id);
    this.onCoachFilterChanged();
  };

  onClearSelectedCoachs = () => {
    this.filteredCoachIds = [];
    this.onCoachFilterChanged();
  };

  private onCoachFilterChanged = () => {
    // We can't really keep the paging or the existing data, we need to refresh based on the selected coach(es)
    this.latestActivities = [];
    this.oldestActivityIndex = undefined;
    fireAndForget([this.fetchMoreClientActivities()]);
  };
}

export const dashboard = new Dashboard();
