import dayjs from 'dayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { CircleView } from 'styleguide/CircleView';
import { TextSMRegular } from 'styleguide/Texts';
import { Day, DayType } from '../types';
import { CIRCLE_DIAMETER } from '../utils';
import { colors } from 'styleguide/colors';
import { View } from 'styleguide/View';
import { Weekday } from 'hevy-shared';
import { endOf, startOf } from 'utils/dateAndTimeUtils';
import { CornerRadius } from 'styleguide/spacing';

const BlueCircle = styled(CircleView).attrs({ diameter: CIRCLE_DIAMETER })<{
  hovering: boolean;
  position: 'end' | 'start' | 'single';
}>`
  background-color: ${(props: { hovering: boolean }) =>
    props.hovering ? colors.primary600 : colors.primary500};
  justify-content: center;
  border-top-right-radius: ${(props: { position: 'end' | 'start' | 'single' }) =>
    props.position === 'end' || props.position === 'single' ? '50%' : '0'};
  border-bottom-right-radius: ${(props: { position: 'end' | 'start' | 'single' }) =>
    props.position === 'end' || props.position === 'single' ? '50%' : '0'};
  border-top-left-radius: ${(props: { position: 'end' | 'start' | 'single' }) =>
    props.position === 'start' || props.position === 'single' ? '50%' : '0'};
  border-bottom-left-radius: ${(props: { position: 'end' | 'start' | 'single' }) =>
    props.position === 'start' || props.position === 'single' ? '50%' : '0'};
`;

const MiddleDay = styled(View)<{
  weekPosition: 'end' | 'start' | 'middle';
}>`
  width: ${CIRCLE_DIAMETER}px;
  height: ${CIRCLE_DIAMETER}px;
  background: repeating-linear-gradient(
    -43deg,
    ${colors.primary25},
    ${colors.primary25} 7px,
    ${colors.primary50} 7px,
    ${colors.primary50} 14px
  );
  justify-content: center;
  border-top-right-radius: ${(props: { weekPosition: 'end' | 'start' | 'middle' }) =>
    props.weekPosition === 'end' ? '50%' : '0'};
  border-bottom-right-radius: ${(props: { weekPosition: 'end' | 'start' | 'middle' }) =>
    props.weekPosition === 'end' ? '50%' : '0'};
  border-top-left-radius: ${(props: { weekPosition: 'end' | 'start' | 'middle' }) =>
    props.weekPosition === 'start' ? '50%' : '0'};
  border-bottom-left-radius: ${(props: { weekPosition: 'end' | 'start' | 'middle' }) =>
    props.weekPosition === 'start' ? '50%' : '0'};
`;

const DayText = styled(TextSMRegular)`
  text-align: center;
  color: ${colors.neutral900};
`;

const MarkingDot = styled(CircleView).attrs({ diameter: CornerRadius.xs })`
  position: absolute;
  top: ${(CIRCLE_DIAMETER - CornerRadius.xs) / 2 + 12}px;
  right: ${(CIRCLE_DIAMETER - CornerRadius.xs) / 2}px;
  background-color: ${colors.primary500};
`;

const dayColor = (day: Day, isMarked: boolean, isDisabled?: boolean) => {
  if (day.type === 'other-month' || !!isDisabled) {
    return colors.neutral400;
  }

  if (isMarked) {
    return colors.white;
  }

  const isToday = dayjs().format('DD-MM-YYYY') === dayjs(day.date).format('DD-MM-YYYY');
  if (isToday) {
    return colors.primary500;
  }

  return colors.neutral900;
};

export const DayView = ({
  day,
  dayType,
  firstWeekday,
  onSelectDate,
  isFutureDatesDisabled,
  isMarked,
}: {
  day: Day;
  dayType: DayType;
  firstWeekday: Weekday;
  onSelectDate: (date: string | null) => void;
  isFutureDatesDisabled?: boolean;
  isMarked?: boolean;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const dateOfMonth = dayjs(day.date).date();

  const weekPosition =
    dayjs(day.date).day() === startOf('week', day.date, firstWeekday).day()
      ? 'start'
      : dayjs(day.date).day() === endOf('week', day.date, firstWeekday).day()
      ? 'end'
      : 'middle';

  const isDisabled = isFutureDatesDisabled && dayjs(day.date).isAfter(dayjs(), 'day');

  return (
    <CircleView
      style={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: !isDisabled ? 'pointer' : 'default',
      }}
      diameter={CIRCLE_DIAMETER}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() =>
        !isDisabled
          ? onSelectDate(dayType === 'start' || dayType === 'single' ? null : day.date)
          : undefined
      }
    >
      {dayType !== 'middle' && dayType !== 'unrelated' ? (
        <BlueCircle hovering={isHovering} position={dayType}>
          <DayText style={{ color: dayColor(day, true, isDisabled), fontWeight: '500' }}>
            {dateOfMonth}
          </DayText>
        </BlueCircle>
      ) : dayType === 'middle' ? (
        <MiddleDay weekPosition={weekPosition}>
          <DayText style={{ color: dayColor(day, false, isDisabled) }}>{dateOfMonth}</DayText>
        </MiddleDay>
      ) : (
        <DayText style={{ color: dayColor(day, false, isDisabled) }}>{dateOfMonth}</DayText>
      )}
      {isMarked && <MarkingDot />}
    </CircleView>
  );
};
