'use client';
import { makeAutoObservable } from 'mobx';
import router from 'next/router';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import API from 'utils/API';
import { sendEvent } from 'utils/analyticsEvents';
import { modal } from '../ModalManager';
import { fireAndForget } from 'utils/async';
import { ClientAggregator } from 'state/aggregators/clientAggregatorStore';
import { humanReadableStringList } from 'utils/pureUtils';
import { Program } from 'hevy-shared';
import dayjs from 'dayjs';

export class AssignProgramToMultipleClientsViewModel {
  selectedClientIds: string[] = [];
  onClose: () => void;
  isCopying = false;
  program: Program;
  searchText = '';
  source?: string;

  isSchedulingEnabled: boolean;
  selectedProgramStartDate: string | null = null;
  selectedProgramDuration: number | null = null;

  constructor(onClose: () => void, program: Program, source?: string) {
    makeAutoObservable(this);
    this.onClose = onClose;
    this.program = program;
    this.source = source;
    this.isSchedulingEnabled = !!program.duration_days;
    this.selectedProgramStartDate = program.start_date ?? dayjs().format('YYYY-MM-DD'); // Default to today
    this.selectedProgramDuration = program.duration_days;
  }

  get coachFirstWeekday() {
    return localStorageStores.coachConfig.firstWeekday;
  }

  get clientsWithNoPrograms() {
    return ClientAggregator.clientsWithoutProgram;
  }

  get clientsWithPrograms() {
    return ClientAggregator.clientsAssignedAProgram;
  }

  get allClients() {
    return memoryStores.clients.allClients;
  }

  onScheduleToggle = () => {
    this.isSchedulingEnabled = !this.isSchedulingEnabled;

    sendEvent('assignProgramToMultipleClientsModal_scheduleToggle_click', {
      program_id: this.program.id,
      is_scheduling_enabled: this.isSchedulingEnabled,
      source: this.source,
    });

    if (!this.isSchedulingEnabled) {
      this.selectedProgramStartDate = null;
      this.selectedProgramDuration = null;
    } else {
      this.selectedProgramStartDate = dayjs().format('YYYY-MM-DD'); // Default to today
      this.selectedProgramDuration = this.program.duration_days;
    }
  };

  onChangeStartDate = (date: string | null) => {
    this.selectedProgramStartDate = date ?? dayjs().format('YYYY-MM-DD'); // Default to today
  };

  onChangeDuration = (duration: number | null) => {
    this.selectedProgramDuration = duration;
  };

  get filteredClients() {
    return this.allClients.filter(client => {
      return (
        client.fullName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
        client.username.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  get filteredClientsWithNoPrograms() {
    return this.clientsWithNoPrograms.filter(client => {
      return (
        client.fullName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
        client.username.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  get filteredClientsWithPrograms() {
    return this.clientsWithPrograms.filter(client => {
      return (
        client.fullName?.toLowerCase().includes(this.searchText.toLowerCase()) ||
        client.username.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  onSearchTextChange = (text: string) => {
    this.searchText = text;
  };

  onMounted = () => {
    memoryStores.clients.fetch();
  };

  confirmAssignProgram = () => {
    // If the user has selected clients with programs, show a warning modal
    if (this.clientsWithPrograms.some(client => this.selectedClientIds.includes(client.id))) {
      const selectedClientsWithPrograms = this.clientsWithPrograms.filter(client =>
        this.selectedClientIds.includes(client.id),
      );

      const clientNames = humanReadableStringList(
        selectedClientsWithPrograms.map(client => client.fullName || client.username),
      );

      modal.openAlertModal({
        title: 'Overwrite existing programs?',
        body: `Are you sure you want to overwrite the program for ${clientNames}? Please note that these clients already have an assigned program, and this action will replace it with the new program that you're assigning.`,
        confirmButtonStyle: 'destructive',
        confirmButtonTitle: 'Confirm',
        cancelButtonTitle: 'Cancel',
        handleAlertConfirm: async () => {
          this.assignProgram();
        },
      });
    } else {
      this.assignProgram();
    }
  };

  assignProgram = async () => {
    sendEvent('assignProgramToMultipleClientsModal_assignProgram_click', {
      program_id: this.program.id,
      client_ids: this.selectedClientIds,
      source: this.source,
    });

    this.isCopying = true;
    try {
      const selectedClientsWithPrograms = this.clientsWithPrograms.filter(client =>
        this.selectedClientIds.includes(client.id),
      );

      const programIdsToDelete = selectedClientsWithPrograms.map(client => client.program?.id);

      for (let i = 0; i < programIdsToDelete.length; i++) {
        const programId = programIdsToDelete[i];

        if (programId !== undefined) {
          await API.deleteProgram(programId);
        }
      }

      const clientIds = this.selectedClientIds;

      await API.assignProgramToClient({
        template_program_id: this.program.id,
        notes: this.program.notes,
        client_ids: clientIds,
        title: this.program.title,
        duration_days: this.selectedProgramDuration,
        start_date: this.selectedProgramStartDate,
      });

      sendEvent('assignProgramToMultipleClientsModal_assignProgram_complete', {
        program_id: this.program.id,
        is_scheduling_enabled: this.isSchedulingEnabled,
        program_duration: this.isSchedulingEnabled ? `${this.selectedProgramDuration} days` : null,
        program_start_date: this.selectedProgramStartDate,
        client_ids: this.selectedClientIds,
        clientsCount: this.selectedClientIds.length,
        source: this.source,
      });
      toast.success('Program succesfully assigned to client(s).');
      fireAndForget([
        localStorageStores.myPrograms.fetch(),
        memoryStores.clients.fetch(),
        memoryStores.myLibraryRoutines.fetch(),
      ]);
    } catch (error) {
      sendEvent('assignProgramToMultipleClientsModal_assignProgram_error', {
        error: JSON.stringify(error),
      });
      toast.error('Unable to assign program.');
    }
    this.isCopying = false;
    this.onClose();
  };

  onSeeDetails = (programId: string) => {
    router.push(`/programs/${programId}`);
    this.onClose();
  };

  get isCopyProgramsButtonEnabled() {
    return this.selectedClientIds.length > 0;
  }

  onSelectedClient = (clientId: string) => {
    if (this.isClientSelected(clientId)) {
      this.selectedClientIds = this.selectedClientIds.filter(id => {
        return id !== clientId;
      });
    } else {
      this.selectedClientIds.push(clientId);
    }
  };

  isClientSelected = (clientId: string) => {
    return this.selectedClientIds.includes(clientId);
  };
}
